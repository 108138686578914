/*
 * @Author: Qy
 * @Date: 2021/10/18 17:13
 * @LastEditTime: 2021/11/05 17:37
 * @LastEditors: Qy
 * @Description:
 */
export const originOpt = [
  {
    value: '1',
    label: '自行投递'
  },
  {
    value: '2',
    label: '公司寻找'
  },
  {
    value: '3',
    label: '猎头推荐'
  },
  {
    value: '4',
    label: '内推'
  },
  {
    value: '5',
    label: '外推'
  }
]

export const channelOpt = [
  {
    value: '1',
    label: '拉勾网'
  },
  {
    value: '2',
    label: 'BOSS直聘'
  },
  {
    value: '3',
    label: '脉脉'
  },
  {
    value: '4',
    label: 'QQ群'
  },
  {
    value: '5',
    label: '论坛'
  },
  {
    value: '6',
    label: '官网'
  },
  {
    value: '7',
    label: '其他渠道'
  }
]

export const workStatusOpt = [
  {
    value: '1',
    label: '在职-未提离职'
  },
  {
    value: '2',
    label: '在职-已提离职'
  },
  {
    value: '3',
    label: '已离职'
  },
  {
    value: '4',
    label: '暂无工作经历'
  }
]

export const sexOpt = [
  {
    value: '0',
    label: '男'
  },
  {
    value: '1',
    label: '女'
  }
]

export const politicalStatusOpt = [
  {
    value: '1',
    label: '群众'
  },
  {
    value: '2',
    label: '中共党员'
  },
  {
    value: '3',
    label: '中共预备党员'
  }
]

export const isOnlyChildOpt = [
  {
    value: '0',
    label: '否'
  },
  {
    value: '1',
    label: '是'
  }
]

export const isSignOpt = [
  {
    value: '0',
    label: '否'
  },
  {
    value: '1',
    label: '是-已确定启动'
  },
  {
    value: '2',
    label: '是-未确定是否启动'
  },
  {
    value: '3',
    label: '是-已确定不启动'
  }
]

export const marryStatusOpt = [
  {
    value: '1',
    label: '未婚未育'
  },
  {
    value: '2',
    label: '已婚未育'
  },
  {
    value: '3',
    label: '已婚已育'
  },
  {
    value: '4',
    label: '其他'
  }
]

export const educationTypeOpt = [
  {
    value: '1',
    label: '学校'
  }, {
    value: '2',
    label: '培训机构'
  }
]

export const isFulltimeOpt = [
  {
    value: '0',
    label: '非统招（成人教育/网络教育/自考/广播电视大学等）'
  },
  {
    value: '1',
    label: '全日制（统招）'
  }
]
