<!--
 * @Author: Qy
 * @Date: 2021/10/16 11:25
 * @LastEditTime: 2022/04/02 14:30
 * @LastEditors: Qy
 * @Description:联系方式
-->
<template>
  <div class="wrapper">
    <FormTitle title="联系方式" />
    <el-form
      ref="form"
      class="form contact-form"
      :inline="true"
      label-position="top"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="电话" prop="phone">
        <Input
          v-model.trim="form.phone"
          placeholder="请输入"
          type="number"
          :disabled="isDisable"
          :clearable="!isMobile"
          maxlength="11"
        />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          v-model.trim="form.email"
          placeholder="请输入"
          type="email"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="微信" prop="wechat">
        <el-input
          v-model.trim="form.wechat"
          placeholder="请输入"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="紧急联系人手机" prop="urgent_phone">
        <Input
          v-model.trim="form.urgent_phone"
          placeholder="请输入"
          type="number"
          :disabled="isDisable"
          :clearable="!isMobile"
          maxlength="11"
        />
      </el-form-item>
      <el-form-item
        label="目前居住地"
        prop="home_place"
        class="home-place-item"
      >
        <el-input
          v-model.trim="form.home_place"
          class="home-place"
          placeholder="请输入"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validEmail } from '@/utils/validate'

import FormTitle from './FormTitle.vue'
import { Input } from '@client/components/input'

export default {
  name: 'ContactForm',
  components: {
    FormTitle,
    Input
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          wechat: '',
          urgent_phone: '',
          home_place: ''
        }
      }
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入'))
      } else if (
        !/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        )
      ) {
        callback(new Error('请输入正确的11位手机号'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入'))
      } else if (!validEmail(value)) {
        callback(new Error('请输入正确的邮箱账号'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        email: [
          { required: true, message: '请输入', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: validateEmail }
        ],
        wechat: [{ required: true, message: '请输入', trigger: 'blur' }],
        urgent_phone: [
          { required: true, message: '请输入', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        home_place: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getData() {
      return {
        ...this.form
      }
    },
    validate() {
      const validatePromise = new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
      return Promise.all([validatePromise])
    }
  }
}
</script>
