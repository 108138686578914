<template>
  <div :class="[$style.wrapper]">
    <div class="success-img-wrap">
      <div class="success-img"></div>
    </div>
    <div class="txt">申请已提交，感谢您的投递！</div>
    <div class="btns">
      <el-button class="btn" @click="edit" size="medium">重新编辑</el-button>
      <el-button class="btn" type="primary" @click="view" size="medium"
        >查看记录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplyResult',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    view() {
      this.$emit('view')
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  text-align: center;
  :global {
    .success-img-wrap {
      padding: 100px 0 32px;
      .success-img {
        margin: auto;
        width: 300px;
        height: 200px;
        background-image: url('~@client/assets/images/success.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .txt {
      font-size: 20px;
      color: $color-text--primary;
      margin-bottom: 24px;
    }
  }
}
</style>
