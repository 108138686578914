<!--
 * @Author: Qy
 * @Date: 2021/10/16 11:25
 * @LastEditTime: 2022/03/31 14:10
 * @LastEditors: Qy
 * @Description:联系方式
-->
<template>
  <div :class="$style.wrapper">
    <FormTitle title="个人信息" />
    <el-form
      ref="form"
      class="form person-form"
      :inline="true"
      label-position="top"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="民族" prop="nation">
        <el-input
          v-model.trim="form.nation"
          placeholder="请输入"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="出生日期" prop="birth_date">
        <el-date-picker
          v-if="!isMobile"
          v-model="form.birth_date"
          :picker-options="pickerOptions"
          :default-value="defaultDate"
          :disabled="isDisable"
          :editable="!isMobile"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择日期"
          class="selector"
        />
        <MobileDatePicker
          v-else
          v-model="form.birth_date"
          type="date"
          :defaultDate="defaultDate"
          :disabled="isDisable"
        />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="form.sex" :disabled="isDisable">
          <el-radio v-for="i in sexOpt" :key="i.value" :label="i.value">{{
            i.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="籍贯（省份+地级市）" prop="native_place">
        <el-input
          v-model.trim="form.native_place"
          placeholder="例如：广东广州"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="政治面貌" prop="political_status">
        <el-select
          v-model="form.political_status"
          filterable
          allow-create
          :disabled="isDisable"
          placeholder="请选择或输入"
          class="selector"
        >
          <el-option
            v-for="(item, idx) in politicalStatusOpt"
            :key="idx"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否签署了竞业协议" prop="is_sign_agreement">
        <ApplyCommonSelector
          v-model="form.is_sign_agreement"
          :options="isSignOpt"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import FormTitle from './FormTitle.vue'
import ApplyCommonSelector from '../select/ApplyCommonSelector.vue'
import { sexOpt, politicalStatusOpt, isSignOpt } from '@client/enum/job'
import MobileDatePicker from './MobileDatePicker.vue'
import dayjs from 'dayjs'

export default {
  name: 'PersonForm',
  components: {
    FormTitle,
    ApplyCommonSelector,
    MobileDatePicker
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {
        return {
          name: '',
          sex: '',
          birth_date: '',
          nation: '',
          native_place: '',
          political_status: '',
          is_sign_agreement: ''
        }
      }
    }
  },
  data() {
    return {
      defaultDate: new Date(dayjs().subtract(20, 'year')),
      sexOpt,
      politicalStatusOpt,
      isSignOpt,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < dayjs('1901-01-01').valueOf() || time.getTime() > dayjs().valueOf()
        }
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择', trigger: 'change' }],
        birth_date: [{ required: true, message: '请选择', trigger: 'change' }],
        nation: [{ required: true, message: '请输入', trigger: 'blur' }],
        native_place: [{ required: true, message: '请输入', trigger: 'blur' }],
        political_status: [
          { required: true, message: '请选择或输入', trigger: 'change' }
        ],
        is_sign_agreement: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    getData() {
      return {
        ...this.form
      }
    },
    validate() {
      const validatePromise = new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
      return Promise.all([validatePromise])
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  :global {
    .selector {
      width: 100%;
    }
  }
}
</style>
