<!--
 * @Author: Qy
 * @Date: 2021/08/13 17:13
 * @LastEditTime: 2021/10/30 11:31
 * @LastEditors: Qy
 * @Description:公用选择器
-->
<template>
  <el-select
    :loading="loading"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    v-model="currentValue"
    :placeholder="placeholder"
    :filterable="filterable"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onChange"
    class="selector"
  >
    <el-option
      v-for="(item,idx) in options"
      :key="idx"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: 'ApplyCommonSelector',
  props: {
    type: {
      type: Number,
      default: 0
    },
    value: {
      type: [Array, String, Number],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择'
    }

  },
  data() {
    return {
      loading: false,
      currentValue: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    clearSelected() {
      this.currentValue = []
      this.$emit('change', this.currentValue)
    },
    onChange() {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.selector{
  width: 100%;
}
</style>
