<!--
 * @Author: Qy
 * @Date: 2021/10/18 16:39
 * @LastEditTime: 2022/03/31 13:23
 * @LastEditors: Qy
 * @Description:教育背景
-->
<template>
  <div :class="$style.wrapper">
    <FormTitle title="教育背景" tips="（*至少填写一行数据）" />
    <el-form
      ref="form"
      :model="formModel"
      :rules="formModel.rules"
      :class="['form', isMobile ? 'edu-form-mobile' : 'edu-form']"
    >
      <!-- pc端 -->
      <el-table
        v-if="!isMobile"
        :data="formModel.tableData"
        :row-key="(record, index) => index"
        class="table-list"
      >
        <el-table-column prop="education_type" min-width="45">
          <template slot="header">
            <span class="is-table-req">教育形式</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.education_type`"
              :rules="formModel.rules.education_type"
            >
              <ApplyCommonSelector
                v-model="row.education_type"
                :options="educationTypeOpt"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="education_name" min-width="75">
          <template slot="header">
            <span class="is-table-req">学校/培训机构名称</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.education_name`"
              :rules="formModel.rules.education_name"
            >
              <el-input
                v-model.trim="row.education_name"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="is_fulltime" min-width="40">
          <template slot="header">
            <span class="is-table-req">教育类别</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.is_fulltime`"
              :rules="formModel.rules.is_fulltime"
            >
              <ApplyCommonSelector
                v-model="row.is_fulltime"
                :options="isFulltimeOpt"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="start_time" width="260">
          <template slot="header">
            <span class="is-table-req">起止时间</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.start_time`"
              :rules="formModel.rules.start_time"
            >
              <!-- <DatePicker
                format="yyyy-MM"
                :picker-options="pickerOptions"
                :start.sync="row.start_time"
                :end.sync="row.end_time"
                :disabled="isDisable"
                type="monthrange"
                unlink-panels
              /> -->
              <PCSofarDatePicker
                :start.sync="row.start_time"
                :end.sync="row.end_time"
                :disabled="isDisable"
                :isShowShortcuts="false"
                :pickerEndOpt="pickerEndOpt"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="academic_degree" min-width="75">
          <template slot="header">
            <span class="is-table-req">学历/证书</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.academic_degree`"
              :rules="formModel.rules.academic_degree"
            >
              <el-input
                v-model.trim="row.academic_degree"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="course" min-width="75">
          <template slot="header">
            <span class="is-table-req">专业/培训课程</span>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              :prop="`tableData.${$index}.course`"
              :rules="formModel.rules.course"
            >
              <el-input
                v-model.trim="row.course"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          width="40px"
          v-if="formModel.tableData.length > 1 && !isDisable"
          class-name="operate-col"
        >
          <template slot-scope="scope">
            <el-popconfirm
              placement="top"
              title="确定要删除吗？"
              icon="el-icon-info"
              confirm-button-type="danger"
              cancel-button-type="default"
              @confirm="handleDelLine(scope.$index, scope.row)"
            >
              <i
                slot="reference"
                class="el-icon-delete"
                title="删除"
                type="text"
              />
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 移动端 -->
      <div v-else class="item-card-wrap">
        <div
          v-for="(item, index) in formModel.tableData"
          :key="index"
          class="item-card"
        >
          <div class="num">
            <div class="lf">
              {{ `${index + 1}` | padNumberFilter }}
            </div>
            <div class="rt" v-if="formModel.tableData.length > 1 && !isDisable">
              <el-popconfirm
                placement="top"
                title="确定要删除吗？"
                icon="el-icon-info"
                confirm-button-type="danger"
                cancel-button-type="default"
                @confirm="handleDelLine(index, item)"
              >
                <i
                  slot="reference"
                  class="el-icon-delete"
                  title="删除"
                  type="text"
                />
              </el-popconfirm>
            </div>
          </div>
          <el-form-item
            label="教育形式"
            :prop="`tableData.${index}.education_type`"
            :rules="formModel.rules.education_type"
          >
            <ApplyCommonSelector
              v-model="item.education_type"
              :options="educationTypeOpt"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="学校/培训机构名称"
            :prop="`tableData.${index}.education_name`"
            :rules="formModel.rules.education_name"
          >
            <el-input
              v-model.trim="item.education_name"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="教育类别"
            :prop="`tableData.${index}.is_fulltime`"
            :rules="formModel.rules.is_fulltime"
          >
            <ApplyCommonSelector
              v-model="item.is_fulltime"
              :options="isFulltimeOpt"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="开始时间"
            :prop="`tableData.${index}.start_time`"
            :rules="formModel.rules.start_time"
          >
            <!-- <el-date-picker
              v-model="item.start_time"
              type="month"
              value-format="yyyy-MM"
              placeholder="开始年月"
              :disabled="isDisable"
              :editable="!isMobile"
              class="pick-time"
            /> -->
            <MobileDatePicker
              v-model="item.start_time"
              placeholder="开始年月"
              type="year-month"
              class="pick-time"
              :maxDate="maxDate"
              :disabled="isDisable"
            />
          </el-form-item>
          <el-form-item
            label="结束时间"
            :prop="`tableData.${index}.end_time`"
            :rules="formModel.rules.end_time"
          >
            <!-- <el-date-picker
              v-model="item.end_time"
              type="month"
              value-format="yyyy-MM"
              placeholder="结束年月"
              :disabled="isDisable"
              :editable="!isMobile"
              class="pick-time"
            /> -->
            <MobileDatePicker
              v-model="item.end_time"
              placeholder="结束年月"
              type="year-month"
              class="pick-time"
              :maxDate="maxDate"
              :disabled="isDisable"
            />
          </el-form-item>
          <el-form-item
            label="学历/证书"
            :prop="`tableData.${index}.academic_degree`"
            :rules="formModel.rules.academic_degree"
          >
            <el-input
              v-model.trim="item.academic_degree"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="专业/培训课程"
            :prop="`tableData.${index}.course`"
            :rules="formModel.rules.course"
          >
            <el-input
              v-model.trim="item.course"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
        </div>
      </div>
      <div v-if="!isDisable" :class="[!isMobile ? 'add-btn-wrap' : '']">
        <el-button
          class="btn-no-border"
          type="primary"
          icon="el-icon-plus"
          plain
          size="medium"
          @click="handleAddLine"
          >新增教育背景</el-button
        >
      </div>
      <div v-else :class="[!isMobile ? 'add-btn-wrap-dis' : '']"></div>
    </el-form>
  </div>
</template>

<script>
import FormTitle from './FormTitle.vue'
import ApplyCommonSelector from '../select/ApplyCommonSelector.vue'
import { educationTypeOpt, isFulltimeOpt } from '@client/enum/job'
// import { DatePicker } from '@client/components/date'
import PCSofarDatePicker from './PCSofarDatePicker.vue'
import MobileDatePicker from './MobileDatePicker.vue'
import dayjs from 'dayjs'

export default {
  name: 'EducationForm',
  components: {
    FormTitle,
    ApplyCommonSelector,
    PCSofarDatePicker,
    MobileDatePicker
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    formModel: {
      type: Object,
      default: () => {
        return {
          tableData: [
            {
              education_type: '',
              education_name: '',
              is_fulltime: '',
              start_time: '',
              end_time: '',
              academic_degree: '',
              course: ''
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      maxDate: new Date(dayjs().add(10, 'year')),
      educationTypeOpt,
      isFulltimeOpt,
      pickerEndOpt: {
        disabledDate(time) {
          return time.getTime() > dayjs().add(10, 'year').valueOf()
        }
      }
    }
  },
  filters: {
    padNumberFilter(value) {
      const txt = value.padStart(2, '0')
      return txt
    }
  },
  methods: {
    handleAddLine() {
      const newItem = {
        education_type: '',
        education_name: '',
        is_fulltime: '',
        start_time: '',
        end_time: '',
        academic_degree: '',
        course: ''
      }
      this.formModel.tableData.push(newItem)
    },
    handleDelLine(index) {
      this.formModel.tableData.splice(index, 1)
    },
    getData() {
      return [...this.formModel.tableData]
    },
    validate() {
      const validatePromise = new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
      return Promise.all([validatePromise])
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  :global {
    .table-list {
      margin-top: 20px;
      &.el-table {
        border: 1px solid #edf1f7;
        border-bottom: none;
        border-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        td {
          &:first-child {
            padding-left: 5px;
          }
          &:last-child {
            &.operate-col {
              padding-right: 5px;
              padding-top: 0 !important;
              vertical-align: inherit;
            }
          }
        }
        th {
          background-color: #f6f7fb;
          color: #223345;
          &:first-child > .cell {
            padding-left: 5px;
          }
        }
        .el-table__row {
          .el-table__cell {
            padding: 8px 0 0;
            vertical-align: top;
          }
        }
        .cell {
          padding-left: 5px;
          padding-right: 5px;
        }
        // safari浏览器中table表格错位的兼容
        .el-table__header-wrapper .el-table__header,
        .el-table__body-wrapper .el-table__body {
          width: 100% !important;
          table-layout: fixed !important;
        }
        // select框多文字换行问题
        .el-input {
          word-break: keep-all;
        }
      }
    }
    .edu-form {
      .el-form-item {
        margin-bottom: 15px;
        &.is-required {
          ::v-deep {
            .el-form-item__error {
              padding-top: 2px;
            }
          }
        }
      }
    }
    .el-icon-delete {
      cursor: pointer;
      color: $color-text--secondary;
      &:hover {
        color: $color--primary;
      }
    }
    .btn-no-border {
      border: none;
      padding: 10px 0;
    }
    .is-table-req {
      &:after {
        font-size: 12px;
        content: '*';
        color: #f05050;
        margin-left: 4px;
      }
    }
    .add-btn-wrap {
      border: 1px #ebedf0 solid;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      text-align: center;
      padding: 5px 0;
      margin-bottom: 32px;
    }
    .add-btn-wrap-dis {
      border: none;
      border-bottom: 1px #ebedf0 solid;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 32px;
    }
    .edu-form-mobile {
      margin-bottom: 12px;
      .item-card-wrap {
        .item-card {
          background-color: #f6f7fb;
          padding: 12px 12px 1px;
          margin-bottom: 12px;
          .num {
            display: flex;
            justify-content: space-between;
            align-content: center;
            margin-bottom: 10px;
            .lf {
              font-size: 16px;
              font-weight: 500;
              color: #223345;
            }
            .rt {
              color: $color-text--secondary;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
