<!--
 * @Author: Qy
 * @Date: 2021/10/16 11:27
 * @LastEditTime: 2021/11/02 20:18
 * @LastEditors: Qy
 * @Description:标题
-->
<template>
  <div
    :class="[
      $style.wrapper,
      {
        [$style['info-title']]: isInfo
      },
      {
        [$style['create-title']]: !isInfo
      }
    ]"
  >
    {{ title }} <span v-if="tips" class="tips">{{ tips }}</span>
  </div>
</template>
<script>
export default {
  name: 'FormTitle',
  props: {
    title: String,
    tips: String,
    isInfo: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  &.create-title {
    background-color: #f6f7fb;
    border-left: #3481fc 3px solid;
    color: #223345;
    font-size: $font-size--extra-small;
    padding: 5px 13px;
    line-height: initial;
    font-weight: 500;
    :global {
      .tips {
        color: #4e5c6a;
      }
    }
  }
  &.info-title {
      font-size: $font-size--medium;
      font-weight: 600;
      color: #223345;
      border-left: #3481fc 3px solid;
      padding: 0 13px;
      margin-bottom: 16px;
    }
}
</style>
