<!--
 * @Author: Qy
 * @Date: 2021/10/18 17:53
 * @LastEditTime: 2022/03/30 20:12
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <div :class="$style.wrapper">
    <FormTitle title="应聘信息" />
    <el-form
      ref="form"
      :inline="true"
      label-position="top"
      :model="form"
      :rules="rules"
      :class="['form', isMobile ? 'apply-form-mobile' : 'apply-form']"
    >
      <el-form-item label="申请职位" prop="job_name">
        <el-input
          v-model.trim="form.job_name"
          placeholder="请输入要申请的职位"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="可入职时间" prop="join_time">
        <el-date-picker
          v-if="!isMobile"
          v-model="form.join_time"
          :picker-options="pickerOptions"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择日期"
          :disabled="isDisable"
          :editable="!isMobile"
          class="pick-time"
        />
        <MobileDatePicker
          v-else
          v-model="form.join_time"
          type="date"
          :minDate="minDate"
          :maxDate="maxDate"
          :disabled="isDisable"
        />
      </el-form-item>
      <el-form-item prop="current_salary">
        <span slot="label"> 目前薪资（请说明薪资构成） </span>
        <el-input
          v-model.trim="form.current_salary"
          placeholder="请输入基本工资，绩效，13薪"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item label="期望薪资（税前）" prop="expected_salary">
        <el-input
          v-model.trim="form.expected_salary"
          placeholder="请输入期望薪资"
          :disabled="isDisable"
          :clearable="!isMobile"
        />
      </el-form-item>
      <el-form-item
        label="渠道来源"
        prop="origin"
        :inline-message="true"
        class="one-line"
      >
        <el-radio-group
          v-model="form.origin"
          :disabled="isDisable"
          class="origin-group-radio"
          @change="onChangeOrigin"
        >
          <el-radio v-for="i in originOpt" :key="i.value" :label="i.value">{{
            i.label
          }}</el-radio>
        </el-radio-group>
        <GroupContanier
          v-if="isDeliverSelf"
          :class="[isMobile ? '' : 'mt-10']"
          title="请选择您从什么渠道了解到公司的招聘信息："
        >
          <div class="channel-group" slot="contain">
            <el-form-item
              class="channel-form-item"
              prop="channel"
              :inline-message="true"
              :rules="[
                {
                  required: isDeliverSelf,
                  message: '请选择渠道',
                  trigger: 'change'
                }
              ]"
            >
              <el-checkbox-group
                v-model="form.channel"
                class="channel-group-checkbox"
                :disabled="isDisable"
                @change="onChangeChannel"
              >
                <el-checkbox
                  v-for="i in channelOpt"
                  :key="i.value"
                  :label="i.value"
                  >{{ i.label }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              prop="other_channel"
              :inline-message="true"
              :rules="[
                {
                  required: isOtherChannel,
                  message: '请输入具体渠道',
                  trigger: 'blur'
                }
              ]"
            >
              <el-input
                v-if="isOtherChannel"
                class="channel-input"
                v-model.trim="form.other_channel"
                placeholder="请输入具体渠道"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
          </div>
        </GroupContanier>
        <div :class="[isMobile ? '' : 'mt-10']" v-if="isReferee">
          <el-form-item
            prop="referee"
            :inline-message="true"
            :rules="[
              {
                required: isReferee,
                message: '请输入推荐人姓名',
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              class="referee-input"
              v-model.trim="form.referee"
              placeholder="请输入推荐人姓名"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item
        label="在职情况"
        prop="current_jon_status"
        class="one-line"
        :inline-message="true"
      >
        <el-radio-group v-model="form.current_jon_status" :disabled="isDisable">
          <el-radio
            v-for="i in workStatusOpt"
            :key="i.value"
            :label="i.value"
            >{{ i.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import FormTitle from './FormTitle.vue'
import GroupContanier from './GroupContanier.vue'
import MobileDatePicker from './MobileDatePicker.vue'
import { originOpt, channelOpt, workStatusOpt } from '@client/enum/job'
import dayjs from 'dayjs'

export default {
  name: 'ApplyForm',
  components: { FormTitle, GroupContanier, MobileDatePicker },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {
        return {
          job_name: '',
          join_time: '',
          current_salary: '',
          expected_salary: '',
          origin: '', // 渠道来源
          channel: [], // 岗位信息渠道来源，
          other_channel: '', // 其他渠道
          referee: '', // 推荐人
          current_jon_status: ''
        }
      }
    }
  },
  data() {
    return {
      minDate: new Date(),
      maxDate: new Date(dayjs().add(1, 'year')),
      originOpt,
      channelOpt,
      workStatusOpt,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < dayjs().subtract(1, 'day').valueOf() || time.getTime() > dayjs().add(1, 'year').valueOf()
        }
      },
      rules: {
        job_name: [{ required: true, message: '请输入', trigger: 'blur' }],
        join_time: [{ required: true, message: '请选择', trigger: 'change' }],
        current_salary: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        expected_salary: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        origin: [{ required: true, message: '请选择', trigger: 'change' }],
        current_jon_status: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isDeliverSelf() {
      return this.form.origin === '1'
    },
    isOtherChannel() {
      return this.form.origin === '1' && this.form.channel.includes('7')
    },
    isReferee() {
      return this.form.origin === '4' || this.form.origin === '5'
    }
  },
  methods: {
    onChangeOrigin() {
      this.form.channel = []
      this.form.other_channel = ''
      this.form.referee = ''
    },
    onChangeChannel(val) {
      if (!val.includes('7')) {
        this.$refs.form.clearValidate(['other_channel'])
        this.form.other_channel = ''
      }
    },
    getData() {
      return {
        ...this.form
      }
    },
    validate() {
      const validatePromise = new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
      return Promise.all([validatePromise])
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  :global {
    .apply-form {
      .channel-group {
        padding: 5px 12px;
        .el-form-item__content {
          line-height: auto;
        }
      }
      .channel-input {
        width: 144px;
        margin-left: 8px;
      }
      .referee-input {
        width: 480px;
        display: flex;
      }
      .channel-group-checkbox {
        display: flex;
        .el-checkbox {
          margin-right: 16px;
        }
      }
    }
    .apply-form-mobile {
      .channel-group {
        padding: 12px 12px 0;
        .channel-form-item {
          .el-form-item__content {
            line-height: 28px;
          }
        }
      }
      .channel-input {
        width: 100%;
      }
      .referee-input {
        width: 100%;
        display: flex;
      }
    }
  }
}
</style>
