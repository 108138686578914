<!--
 * @Author: Qy
 * @Date: 2021/10/19 11:12
 * @LastEditTime: 2021/11/01 18:06
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <el-tooltip
    popper-class="tooltip-wrapper"
    :content="content"
    :placement="placement"
  >
    <i class="el-icon-info-outline"></i>
    <div slot="content" class="content fs-14" :style="{ width: tooltipWidth }">
      {{ content }}
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ToolTipIcon',
  props: {
    content: {
      type: String
    },
    placement: {
      type: String,
      default: 'top'
    },
    tooltipWidth: {
      type: String,
      default: '268px'
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" module>
</style>
