<!--
 * @Author: Qy
 * @Date: 2021/10/19 14:36
 * @LastEditTime: 2021/11/01 14:44
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <div :class="$style.wrapper">
    <div class="title">{{ title }}</div>
    <div class="contain">
      <slot name="contain" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupContanier',
  components: {},
  props: {
    title: {
      type: String,
      default: '标题'
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" module>
.wrapper {
  border-radius: 4px;
  border: 1px solid #edf1f7;
  :global {
    .title {
      background: #f6f7fb;
      color: #4e5c6a;
      font-size: 14px;
      font-weight: 600;
      padding-left: 12px;
      line-height: 40px;
    }
  }
}
</style>
