<!--
 * @Author: Qy
 * @Date: 2022/03/15 14:55
 * @LastEditTime: 2022/04/01 11:41
 * @LastEditors: Qy
 * @Description:PC端的【至今】选择日期组件 value-format="yyyy-MM-dd HH:mm:ss" .replace(/-/g, '/')
-->
<template>
  <div class="sofar-picker-wrapper">
    <el-date-picker
      v-model="date[0]"
      type="month"
      :placeholder="startPlaceholder"
      :picker-options="pickerStartOpt || pickerStartOptions"
      @change="onChanged($event, 'start', date)"
      :disabled="disabled"
    />
    <div class="range-separator">~</div>
    <el-date-picker
      v-if="isShowShortcuts"
      v-model="date[1]"
      type="month"
      :class="{ active: isSofarDate }"
      :placeholder="isSofarDate ? '至今' : endPlaceholder"
      :picker-options="pickerEndSofarOptions"
      @change="onChanged($event, 'end', date)"
      :disabled="disabled"
    />
    <el-date-picker
      v-else
      v-model="date[1]"
      type="month"
      :placeholder="endPlaceholder"
      :picker-options="pickerEndOpt || pickerEndOptions"
      @change="onChanged($event, 'end', date)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'PCSofarDatePicker',
  props: {
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    startPlaceholder: {
      type: String,
      default: '开始年月'
    },
    endPlaceholder: {
      type: String,
      default: '结束年月'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否显示至今的快捷面板
    isShowShortcuts: {
      type: Boolean,
      default: true
    },
    pickerStartOpt: {
      type: Object,
      default: () => {}
    },
    pickerEndOpt: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const _this = this
    return {
      pickerStartOptions: {
        disabledDate(time) {
          return time.getTime() < dayjs('1901-01-01').valueOf() || time.getTime() > Date.now()
        }
      },
      pickerEndSofarOptions: {
        disabledDate(time) {
          return time.getTime() < dayjs('1901-01-01').valueOf() || time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '至今',
            onClick(picker) {
              console.log('picker', picker)
              picker.$emit('pick', '至今')
              _this.$nextTick(() => {
                _this.isSofarDate = true
              })
            }
          }
        ]
      },
      pickerEndOptions: {
        disabledDate(time) {
          return time.getTime() < dayjs('1901-01-01').valueOf() || time.getTime() > Date.now()
        }
      },
      isSofarDate: false, // 当前的值 是否 为'至今'的标识
      date: []
    }
  },
  watch: {
    end() {
      if (!this.date) {
        this.date = []
      }
      this.date = [this.start, this.end === 'Invalid Date' ? '' : this.end]
      this.end === '至今' && (this.isSofarDate = true)
      console.log(this.date, this.end, this.isSofarDate)
    }
  },
  created() {
    this.start && this.end && (this.date = [this.start, this.end])
    this.end === '至今' && (this.isSofarDate = true)
  },
  methods: {
    onChanged(date, type, arrDate) {
      console.log(date, type, arrDate, arrDate.length)
      if ((date !== null || date !== '') && type === 'start') {
        this.$emit('update:start', dayjs(arrDate[0]).format('YYYY/MM/DD HH:mm:ss'))
      }
      if ((date !== null || date !== '') && type === 'end') {
        if (date === '至今') {
          this.$emit('update:end', '至今')
        } else {
          this.$emit('update:end', dayjs(arrDate[1]).format('YYYY/MM/DD HH:mm:ss'))
        }
      }
      if ((date === null || date === '') && type === 'start') {
        this.$emit('update:start', '')
      }
      if ((date === null || date === '') && type === 'end') {
        this.$emit('update:end', '')
        this.isSofarDate = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sofar-picker-wrapper {
  width: 235px;
  display: flex;
  .range-separator {
    padding: 0 2px;
  }
  ::v-deep {
    .el-date-editor {
      display: flex;
      &.active {
        .el-input__inner {
          &::-webkit-input-placeholder {
            color: #223345;
          }
        }
      }
      .el-input__inner {
        padding-left: 12px;
        width: 109px !important;
      }
      .el-input__prefix {
        right: 5px;
        left: auto;
      }
      &.el-input {
        width: 115px;
      }
    }
  }
}
</style>
