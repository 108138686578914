<!--
 * @Author: Qy
 * @Date: 2022/03/15 14:55
 * @LastEditTime: 2022/03/30 20:55
 * @LastEditors: Qy
 * @Description:移动端的【至今】选择日期组件
 * https://youzan.github.io/vant/v2/#/zh-CN/datetime-picker
-->
<template>
  <div @click="openPopup">
    <div :class="['mobile-date-picker', disabled ? 'disabled-pick' : '']">
      <i class="el-icon-date icon" />
      <span :class="['text', value ? '' : 'placeholder']">{{
        value ? formatValue : placeholder
      }}</span>
    </div>
    <van-popup
      v-model="showPopup"
      position="bottom"
      round
      :transition-appear="true"
      get-container="body"
    >
      <van-picker
        ref="sofarDatePicker"
        :columns="columns"
        :readonly="disabled"
        :title="placeholder"
        value-key="text"
        show-toolbar
        @confirm="confirmPicker"
        @cancel="cancelPicker"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'MobileSofarDatePicker',
  props: {
    value: {
      type: [Array, String, Number, Boolean],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择日期'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: '',
      showPopup: false,
      columns: [],
      formatValue: ''// 返显时间的值
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.formatValue = newVal === '至今' ? '至今' : dayjs(newVal).format('YYYY-MM')
        }
        console.log('的值', newVal)
      },
      immediate: true
      // console.log(newVal, dayjs(newVal), dayjs(newVal).$y)
    }
  },
  methods: {
    // 打开弹窗
    openPopup() {
      if (this.disabled) {
        this.showPopup = false
        return false
      }
      this.showPopup = true
      this.initLastData() // 日期数据列表
      this.value !== '' && this.timeInit() // 返显值
    },
    // 取消
    cancelPicker() {
      this.showPopup = false
    },
    // 停止惯性滚动并触发 confirm 事件
    confirmPicker(val) {
      this.showPopup = false
      if (val.length > 0 && val[0].text !== '至今') {
        this.currentValue = `${val[0].value}/${val[1].value}/01 00:00:00`
      } else {
        this.currentValue = '至今'
      }
      this.$emit('input', this.currentValue)
      console.log(
        '已选择 ',
        val,
        this.$refs.sofarDatePicker.getValues(),
        this.currentValue
      )
    },
    // 在onload 的时候初始化 columns
    initLastData() {
      const myDate = new Date()
      const data = {
        year: myDate.getFullYear(),
        month: myDate.getMonth() + 1,
        date: myDate.getDate()
      }
      const options1 = [
        {
          text: '至今',
          value: '-1'
        }
      ]
      const options2 = []
      // 初始化近80年
      for (let i = data.year; i > data.year - 80; i--) {
        options1.push({
          text: i,
          value: i
        })
      }
      // 当前年 ,限制月份小于等于当前月
      for (let j = 1; j <= data.month; j++) {
        options2.push({
          text: '0' + j,
          value: j
        })
      }
      // 初始化12个月
      const dataFull = []
      for (let z = 1; z <= 12; z++) {
        dataFull.push({
          text: z < 10 ? '0' + z : '' + z,
          value: z < 10 ? '0' + z : '' + z
        })
      }
      // 回填 ，如果是至今，则月份选项为空
      if (this.value !== '' && this.value !== '至今') {
        const arr = this.value.split('-')
        // 如果非当年前，选项为12个月
        this.columns = [
          {
            values: options1
          },
          {
            values: arr[0] < data.year ? dataFull : options2
          }
        ]
      } else {
        this.columns = [
          {
            values: options1
          },
          {
            values: []
          }
        ]
      }
    },
    // 当年的选项选项变化的时候
    onChange(picker, value) {
      // 此块代码可以封装，为了比较好的展示，我这边的放了全部的代码
      const myDate = new Date()
      const year = myDate.getFullYear()
      const month = myDate.getMonth() + 1
      const data = []
      const options2 = []
      for (let z = 1; z <= 12; z++) {
        data.push({
          text: z < 10 ? '0' + z : z,
          value: z
        })
      }
      for (let j = 1; j <= month; j++) {
        options2.push({
          text: '0' + j,
          value: j
        })
      }
      // 如果选项变成至今，则月份选项为空
      if (value[0].value === '-1') {
        picker.setColumnValues(1, [])
        return
      }
      // 如果年选项小于当前年，则显示12个月
      if (value[0].value < year) {
        picker.setColumnValues(1, data)
      } else {
        picker.setColumnValues(1, options2)
      }
    },
    // 回填组件显示时显示的值
    timeInit() {
      const myDate = new Date()
      const data = {
        year: myDate.getFullYear(),
        month: myDate.getMonth() + 1
      }

      const dataFull = []
      const options2 = []
      // 12个月
      for (let z = 1; z <= 12; z++) {
        dataFull.push({
          text: z < 10 ? '0' + z : '' + z,
          value: z < 10 ? '0' + z : '' + z
        })
      }
      // 小于当前月的选项
      for (let j = 1; j <= data.month; j++) {
        options2.push({
          text: '0' + j,
          value: j
        })
      }
      this.$nextTick(() => {
        //  获取组件
        const sofarDatePicker = this.$refs.sofarDatePicker
        console.log('sofarDatePicker', sofarDatePicker)
        if (this.value !== '' && this.value !== '至今') {
          const arr = dayjs(this.value).format('YYYY-MM').split('-')
          console.log('pick得知', arr)
          sofarDatePicker.setColumnValue(0, Number(arr[0]))
          if (arr[0] < data.year) {
            // 回填月份的选项
            sofarDatePicker.setColumnValues(1, dataFull)
          } else {
            sofarDatePicker.setColumnValues(1, options2)
          }
          /// 回填选中的值
          sofarDatePicker.setColumnValue(1, arr[1])
        } else {
          sofarDatePicker.setColumnIndex(0, 0)
          sofarDatePicker.setColumnValues(1, [])
        }
        console.log(this.columns, this.value)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-date-picker {
  width: 100%;
  border: 1px solid #d1d4db;
  border-radius: 4px;
  position: relative;
  display: flex;
  .icon {
    height: 100%;
    text-align: center;
    transition: all 0.3s;
    position: absolute;
    left: 10px;
    top: 10px;
    color: rgba(140, 148, 165, 0.6);
  }
  .text {
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    color: #223345;
    display: inline-block;
    font-size: inherit;
    padding: 0 15px 0 30px;
    width: 100%;
    color: #223345;
    &.placeholder {
      color: #babfc9;
    }
  }
  &.disabled-pick {
    opacity: 1;
    -webkit-text-fill-color: #8c94a5;
    cursor: not-allowed;
    border-color: #d1d4db;
    .text {
      background-color: #f6f7fb;
      color: #8c94a5;
    }
  }
}
</style>
