<!--
 * @Author: Qy
 * @Date: 2022/03/15 14:55
 * @LastEditTime: 2022/04/01 11:18
 * @LastEditors: Qy
 * @Description:移动端的选择日期组件
 * https://youzan.github.io/vant/v2/#/zh-CN/datetime-picker
-->
<template>
  <div>
    <div
      @click="openPopup"
      :class="['mobile-date-picker', disabled ? 'disabled-pick' : '']"
    >
      <i class="el-icon-date icon" />
      <span :class="['text', value ? '' : 'placeholder']">{{
        value ? formatValue : placeholder
      }}</span>
    </div>
    <van-popup
      v-model="showPopup"
      position="bottom"
      round
      :transition-appear="true"
    >
      <van-datetime-picker
        v-model="currentValue"
        :type="type"
        :title="placeholder"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="cancelPicker"
        @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'MobileDatePicker',
  props: {
    value: {
      type: [Array, String, Number, Boolean],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择日期'
    },
    // date time year-month month-day datehour
    type: {
      type: String,
      default: 'date'
    },
    minDate: {
      type: [String, Number, Date],
      default: () => {
        return new Date('1901-01-01')
      }
    },
    maxDate: {
      type: [String, Number, Date],
      default: () => {
        return new Date()
      }
    },
    defaultDate: {
      type: [String, Number, Date],
      default: () => {
        return new Date()
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: null,
      showPopup: false,
      formatValue: null
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.currentValue = this.transformDateVal(
            new Date(dayjs(newVal).$y, dayjs(newVal).$M, dayjs(newVal).$D)
          )
          switch (this.type) {
            case 'date':
              this.formatValue = dayjs(newVal).format('YYYY-MM-DD')
              break
            case 'year-month':
              this.formatValue = dayjs(newVal).format('YYYY-MM')
              break
            default:
              this.formatValue = ''
              break
          }
          console.log('处理一个显示的值', this.type, newVal)
        }
        // console.log(newVal, dayjs(newVal), dayjs(newVal).$y)
      },
      immediate: true
    }
  },
  methods: {
    openPopup() {
      if (this.disabled) {
        this.showPopup = false
        return false
      }
      this.showPopup = true
      this.currentValue =
        this.value !== ''
          ? new Date(
            dayjs(this.value).$y,
            dayjs(this.value).$M,
            dayjs(this.value).$D
          )
          : this.defaultDate
    },
    cancelPicker() {
      this.showPopup = false
    },
    confirmPicker(val) {
      this.showPopup = false
      console.log('已选择 ', val, this.currentValue)
      console.log(this.transformDateVal(val))
      const resultValue = this.type === 'year-month' ? dayjs(this.transformDateVal(val)).format('YYYY/MM/DD HH:mm:ss') : this.transformDateVal(val)
      this.$emit('input', resultValue)
    },
    // 格式化确认返回的数据
    transformDateVal(val) {
      const year = typeof val === 'object' ? val.getFullYear() : ''
      let month = typeof val === 'object' ? val.getMonth() + 1 : ''
      let day = typeof val === 'object' ? val.getDate() : ''
      let hour = typeof val === 'object' ? val.getHours() : ''
      let minute = typeof val === 'object' ? val.getMinutes() : ''
      if (month >= 1 && month <= 9) {
        month = `0${month}`
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`
      }
      // console.log(typeof val, dayjs(val))
      let timeValue = ''
      switch (this.type) {
        case 'year-month':
          timeValue = `${year}-${month}`
          break
        case 'date':
          timeValue = `${year}-${month}-${day}`
          break
        case 'month-day':
          timeValue = `${month}-${day}`
          break
        case 'time':
          timeValue = `${val}`
          break
        case 'datehour':
          timeValue = `${year}-${month}-${day} ${hour}:${minute}`
          break
        default:
          timeValue = ''
          break
      }
      return timeValue
    },
    // 格式化显示的列表
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      } else if (type === 'second') {
        return `${value}秒`
      }
      console.log(value)
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-date-picker {
  width: 100%;
  border: 1px solid #d1d4db;
  border-radius: 4px;
  position: relative;
  display: flex;
  .icon {
    height: 100%;
    text-align: center;
    transition: all 0.3s;
    position: absolute;
    left: 10px;
    top: 10px;
    color: rgba(140, 148, 165, 0.6);
  }
  .text {
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    color: #223345;
    display: inline-block;
    font-size: inherit;
    padding: 0 15px 0 30px;
    width: 100%;
    color: #223345;
    &.placeholder {
      color: #babfc9;
    }
  }
  &.disabled-pick {
    opacity: 1;
    -webkit-text-fill-color: #8c94a5;
    cursor: not-allowed;
    border-color: #d1d4db;
    .text {
      background-color: #f6f7fb;
      color: #8c94a5;
    }
  }
}
</style>
