<!--
 * @Author: Qy
 * @Date: 2021/10/18 16:39
 * @LastEditTime: 2022/09/06 10:47
 * @LastEditors: Qy
 * @Description:工作经历
-->
<template>
  <div :class="$style.wrapper">
    <FormTitle
      title="工作经历"
      tips="（*请如实填写所有工作履历,无工作履历可不填）"
    />
    <el-form
      ref="form"
      :model="formModel"
      :rules="formModel.rules"
      :class="['form', isMobile ? 'exp-form-mobile' : 'exp-form']"
    >
      <!-- pc端 -->
      <div v-if="!isMobile" class="desk-card-wrap">
        <div
          v-for="(row, index) in formModel.tableData"
          :key="index"
          class="item-card"
        >
          <div class="num">
            <div class="lf">
              {{ `${index + 1}` | padNumberFilter }}
            </div>
            <div class="rt" v-if="formModel.tableData.length > 1 && !isDisable">
              <el-popconfirm
                placement="top"
                title="确定要删除吗？"
                icon="el-icon-info"
                confirm-button-type="danger"
                cancel-button-type="default"
                @confirm="handleDelLine(index, row)"
              >
                <i
                  slot="reference"
                  class="el-icon-delete"
                  title="删除"
                  type="text"
                />
              </el-popconfirm>
            </div>
          </div>
          <div class="form-item-wrap">
            <el-form-item
              label="公司名称："
              :prop="`tableData.${index}.company_name`"
              :rules="formModel.rules.company_name"
            >
              <el-input
                v-model.trim="row.company_name"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
            <el-form-item
              label="起止时间："
              :prop="`tableData.${index}.start_date`"
              :rules="formModel.rules.start_date"
            >
              <!-- <DatePicker
                format="yyyy-MM"
                :start.sync="row.start_date"
                :end.sync="row.end_date"
                :disabled="isDisable"
                type="monthrange"
                unlink-panels
              /> -->
              <!-- {{row.start_date }} ~ {{row.end_date }} -->
              <PCSofarDatePicker
                :start.sync="row.start_date"
                :end.sync="row.end_date"
                :disabled="isDisable"
              />
            </el-form-item>
            <el-form-item
              label="所在部门："
              :prop="`tableData.${index}.department`"
              :rules="formModel.rules.department"
            >
              <el-input
                v-model.trim="row.department"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
                class="w141"
              />
            </el-form-item>
            <el-form-item
              label="职位名称："
              :prop="`tableData.${index}.job_title`"
              :rules="formModel.rules.job_title"
              class="no-mr"
            >
              <el-input
                v-model.trim="row.job_title"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
            <el-form-item
              label="薪酬待遇："
              :prop="`tableData.${index}.salary`"
              :rules="formModel.rules.salary"
            >
              <el-input
                v-model.trim="row.salary"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
              />
            </el-form-item>
            <el-form-item
              :prop="`tableData.${index}.auth_phone`"
              :rules="formModel.rules.auth_phone"
            >
              <span slot="label" class="auth-phone">
                证明人及联系方式<el-tooltip
                  class="item"
                  effect="dark"
                  content="请说明证明人姓名/岗位/联系电话或微信或QQ等"
                  placement="top"
                >
                  <i class="el-icon-info-outline"></i>
                </el-tooltip>
              </span>
              <el-input
                v-model.trim="row.auth_phone"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
                class="w235"
              />
            </el-form-item>

            <el-form-item
              :prop="`tableData.${index}.leave_reason`"
              :rules="formModel.rules.leave_reason"
              class="no-mr"
            >
              <span slot="label" class="auth-phone">
                看新机会原因/离职原因
              </span>
              <el-input
                v-model.trim="row.leave_reason"
                placeholder="请输入"
                :disabled="isDisable"
                :clearable="!isMobile"
                class="w423"
              />
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 移动端   -->
      <div v-else class="item-card-wrap">
        <div
          v-for="(item, index) in formModel.tableData"
          :key="index"
          class="item-card"
        >
          <div class="num">
            <div class="lf">
              {{ `${index + 1}` | padNumberFilter }}
            </div>
            <div class="rt" v-if="formModel.tableData.length > 1 && !isDisable">
              <el-popconfirm
                placement="top"
                title="确定要删除吗？"
                icon="el-icon-info"
                confirm-button-type="danger"
                cancel-button-type="default"
                @confirm="handleDelLine(index, item)"
              >
                <i
                  slot="reference"
                  class="el-icon-delete"
                  title="删除"
                  type="text"
                />
              </el-popconfirm>
            </div>
          </div>
          <el-form-item
            label="公司名称"
            :prop="`tableData.${index}.company_name`"
            :rules="formModel.rules.company_name"
          >
            <el-input
              v-model.trim="item.company_name"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="开始时间"
            :prop="`tableData.${index}.start_date`"
            :rules="formModel.rules.start_date"
          >
            <!-- <el-date-picker
              v-model="item.start_date"
              type="month"
              value-format="yyyy-MM"
              placeholder="开始年月"
              :disabled="isDisable"
              :editable="!isMobile"
              class="pick-time"
            /> -->
            <MobileDatePicker
              v-model="item.start_date"
              placeholder="开始年月"
              type="year-month"
              class="pick-time"
              :disabled="isDisable"
            />
          </el-form-item>
          <el-form-item
            label="结束时间"
            :prop="`tableData.${index}.end_date`"
            :rules="formModel.rules.end_date"
          >
            <!-- <el-date-picker
              v-model="item.end_date"
              type="month"
              value-format="yyyy-MM"
              placeholder="结束年月"
              :disabled="isDisable"
              :editable="!isMobile"
              class="pick-time"
            /> -->
            <!-- <MobileDatePicker
              v-model="item.end_date"
              placeholder="结束年月"
              type="year-month"
              class="pick-time"
              :maxDate="maxDate"
              :disabled="isDisable"
            /> -->
            <!-- {{ item.end_date }} -->
            <MobileSofarDatePicker
              v-model="item.end_date"
              placeholder="结束年月"
              class="pick-time"
              :disabled="isDisable"
            />
          </el-form-item>
          <el-form-item
            label="所在部门"
            :prop="`tableData.${index}.department`"
            :rules="formModel.rules.department"
          >
            <el-input
              v-model.trim="item.department"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="职位名称"
            :prop="`tableData.${index}.job_title`"
            :rules="formModel.rules.job_title"
          >
            <el-input
              v-model.trim="item.job_title"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="看新机会原因/离职原因"
            :prop="`tableData.${index}.leave_reason`"
            :rules="formModel.rules.leave_reason"
          >
            <el-input
              v-model.trim="item.leave_reason"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            label="薪酬待遇"
            :prop="`tableData.${index}.salary`"
            :rules="formModel.rules.salary"
          >
            <el-input
              v-model.trim="item.salary"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
          <el-form-item
            :prop="`tableData.${index}.auth_phone`"
            :rules="formModel.rules.auth_phone"
          >
            <span slot="label">
              证明人联系方式
              <ToolTipIcon
                content="请说明证明人姓名/岗位/联系电话或微信或QQ等"
              />
            </span>
            <el-input
              v-model.trim="item.auth_phone"
              placeholder="请输入"
              :disabled="isDisable"
              :clearable="!isMobile"
            />
          </el-form-item>
        </div>
      </div>
      <div v-if="!isDisable" :class="[!isMobile ? 'add-btn-wrap' : '']">
        <el-button
          class="btn-no-border"
          type="primary"
          icon="el-icon-plus"
          plain
          size="medium"
          @click="handleAddLine"
          >新增工作经历</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import FormTitle from './FormTitle.vue'
import ToolTipIcon from './ToolTipIcon.vue'
// import { DatePicker } from '@client/components/date'
import MobileDatePicker from './MobileDatePicker.vue'
import PCSofarDatePicker from './PCSofarDatePicker.vue'
import MobileSofarDatePicker from './MobileSofarDatePicker.vue'
// import dayjs from 'dayjs'

export default {
  name: 'ExperienceForm',
  components: {
    FormTitle,
    ToolTipIcon,
    // DatePicker,
    MobileDatePicker,
    PCSofarDatePicker,
    MobileSofarDatePicker
  },
  props: {
    isDisable: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    formModel: {
      type: Object,
      default: () => {
        return {
          tableData: [
            {
              company_name: '',
              start_date: '',
              end_date: '',
              department: '',
              job_title: '',
              leave_reason: '',
              salary: '',
              auth_phone: ''
            },
            {
              company_name: '',
              start_date: '',
              end_date: '',
              department: '',
              job_title: '',
              leave_reason: '',
              salary: '',
              auth_phone: ''
            },
            {
              company_name: '',
              start_date: '',
              end_date: '',
              department: '',
              job_title: '',
              leave_reason: '',
              salary: '',
              auth_phone: ''
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      // maxDate: new Date(dayjs().add(10, 'year'))
    }
  },
  filters: {
    padNumberFilter(value) {
      const txt = value.padStart(2, '0')
      return txt
    }
  },
  methods: {
    handleAddLine() {
      const newItem = {
        company_name: '',
        start_date: '',
        end_date: '',
        department: '',
        job_title: '',
        leave_reason: '',
        salary: '',
        auth_phone: ''
      }
      this.formModel.tableData.push(newItem)
    },
    handleDelLine(index) {
      this.formModel.tableData.splice(index, 1)
    },
    getData() {
      return [...this.formModel.tableData]
    },
    validate() {
      const validatePromise = new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
      return Promise.all([validatePromise])
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  :global {
    // .table-list {
    //   margin-top: 20px;
    //   &.el-table {
    //     border: 1px solid #edf1f7;
    //     border-bottom: none;
    //     border-radius: 4px;
    //     border-bottom-right-radius: 0;
    //     border-bottom-left-radius: 0;
    //     td {
    //       &:first-child {
    //         padding-left: 5px;
    //       }
    //       &:last-child {
    //         &.operate-col {
    //           padding-right: 5px;
    //           padding-top: 0 !important;
    //           vertical-align: inherit;
    //         }
    //       }
    //     }
    //     th {
    //       background-color: #f6f7fb;
    //       color: #223345;
    //       &:first-child > .cell {
    //         padding-left: 5px;
    //       }
    //     }
    //     .el-table__row {
    //       .el-table__cell {
    //         padding: 8px 0 0;
    //         vertical-align: top;
    //       }
    //     }
    //     .cell {
    //       padding-left: 5px;
    //       padding-right: 5px;
    //     }
    //     // safari浏览器中table表格错位的兼容
    //     .el-table__header-wrapper .el-table__header,
    //     .el-table__body-wrapper .el-table__body {
    //       width: 100% !important;
    //       table-layout: fixed !important;
    //     }
    //   }
    // }
    .exp-form {
      margin-top: 0 !important;
      .el-form-item {
        margin-bottom: 15px;
        margin-right: 24px !important;
        width: auto !important;
        &.no-mr {
          margin-right: 0 !important;
        }
        .el-form-item__label {
          width: 70px;
          white-space: nowrap;
        }
        .el-form-item__content {
          margin-left: 70px;
        }

        // &.is-required {
        //   ::v-deep {
        //     .el-form-item__error {
        //       padding-top: 2px;
        //     }
        //   }
        // }
      }
      .el-form-item.is-required:not(.is-no-asterisk)
        > .el-form-item__label:before {
        content: '';
        margin-right: 0;
      }
    }
    .el-form--inline {
      .el-form-item__content {
        display: initial;
      }
    }
    .el-icon-delete {
      cursor: pointer;
      color: $color-text--secondary;
      &:hover {
        color: $color--primary;
      }
    }
    .btn-no-border {
      border: none;
      padding: 10px 0;
    }
    // .add-btn-wrap {
    //   // border: 1px #ebedf0 solid;
    //   // border-radius: 4px;
    //   // border-top-right-radius: 0;
    //   // border-top-left-radius: 0;
    //   // text-align: center;
    //   padding: 5px 0;
    // }
    .auth-phone {
      display: block;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      white-space: normal;
      i {
        margin-left: 5px;
      }
    }
    // 又改UI card 桌面版
    .desk-card-wrap {
      .item-card {
        margin-top: 12px;
        background-color: #f6f7fb;
        .num {
          display: flex;
          justify-content: space-between;
          align-content: center;
          padding: 16px;
          .lf {
            font-size: 16px;
            font-weight: 700;
            color: #223345;
          }
          .rt {
            color: $color-text--secondary;
          }
        }
        .form-item-wrap {
          display: flex;
          flex-wrap: wrap;
          margin: 0 16px;
          .el-input input {
            width: 188px;
          }
          // .el-date-editor--monthrange {
          //   width: 188px;
          // }
          .el-date-editor .el-range-separator {
            padding: 0;
          }
          .el-input.w141 input {
            width: 141px !important;
          }
          .el-input.w235 input {
            width: 235px !important;
          }
          .el-input.w423 input {
            width: 423px !important;
          }
        }
      }
    }

    // 移动版
    .exp-form-mobile {
      margin-bottom: 12px;
      .item-card-wrap {
        .item-card {
          background-color: #f6f7fb;
          padding: 12px 12px 1px;
          margin-bottom: 12px;
          .num {
            display: flex;
            justify-content: space-between;
            align-content: center;
            margin-bottom: 10px;
            .lf {
              font-size: 16px;
              font-weight: 500;
              color: #223345;
            }
            .rt {
              color: $color-text--secondary;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .el-form-item__label {
            float: none;
          }
          .el-form-item.is-required:not(.is-no-asterisk)
            > .el-form-item__label:before {
            content: '';
          }
        }
      }
    }
  }
}
</style>
